import { useTranslation } from "react-i18next";

function AnalysisHtml({ htmlAnalysis }) {
    const { t } = useTranslation();

    const anchorsWithoutTitle = htmlAnalysis.anchorsWithoutTitle;
    const deprecatedTags = htmlAnalysis.deprecatedTags;
    const iframeCount = htmlAnalysis.iframeCount;
    const isHeadingOrdered = htmlAnalysis.isHeadingOrdered;
    const hasMicrodata = htmlAnalysis.hasMicrodata;
    const hasReponsiveDesign = htmlAnalysis.hasResponsiveDesign;
    const hasViewportMetaTag = htmlAnalysis.hasViewportMetaTag;
    const headingCounts = htmlAnalysis.headingCounts;
    const isHTML5Doctype = htmlAnalysis.isHtml5;
    const hasHTML5Elements = htmlAnalysis.hasHtml5Elements;
    const isValidLang = htmlAnalysis.isValidLang;
    const hasLangAttribute = htmlAnalysis.hasLangAttribute;
    const hasFavicon = htmlAnalysis.hasFavicon;
    const hasSecureResources = htmlAnalysis.hasSecureResources;
    const hasImagesAlt = htmlAnalysis.hasImagesAlt;
    const nonDescriptiveAnchors = htmlAnalysis.nonDescriptiveAnchors;
    const socialTags = htmlAnalysis.socialTags;

    return (
        <section>
            <h2 className="font-semibold text-2xl text-fucsia-def">
                {t("HTMLAnalysis")}
            </h2>

            <div className="analysis-result-item">
                <h3 className="mb-1 font-medium text-xl">
                    {t("Iframe")}
                </h3>

                {iframeCount < 4 ? (
                    <p className="analysis-text-success">
                        {t("IframeCorrect").replace("%count%", iframeCount)}
                    </p>
                ) : (
                    <p className="analysis-text-error">
                        {t("IframeIncorrect").replace("%count%", iframeCount)}
                    </p>
                )}
            </div>
            
            <div className="analysis-result-item">
                <h3 className="mb-1 font-medium text-xl">
                    {t("Microdata")}
                </h3>

                {hasMicrodata ? (
                    <p className="analysis-text-success">
                        {t("MicrodataCorrect")}
                    </p>
                ) : (
                    <p className="analysis-text-error">
                        {t("MicrodataIncorrect")}
                    </p>
                )}
            </div>
            
            <div className="analysis-result-item">
                <h3 className="mb-1 font-medium text-xl">
                    {t("Viewport")}
                </h3>

                {hasReponsiveDesign ? (
                    <p className="analysis-text-success">
                        {t("ViewportCorrect")}
                    </p>
                ) : (
                    <p className="analysis-text-error">
                        {t("ViewportIncorrect")}
                    </p>
                )}
            </div>

            <div className="analysis-result-item">
                <h3 className="mb-1 font-medium text-xl">
                    {t("ViewportMetaTag")}
                </h3>

                {hasViewportMetaTag ? (
                    <p className="analysis-text-success">
                        {t("MetaViewPortCorrect")}
                    </p>
                ) : (
                    <p className="analysis-text-error">
                        {t("MetaViewPortIncorrect")}
                    </p>
                )}
            </div>
            
            <div className="analysis-result-item">
                <h3 className="mb-1 font-medium text-xl">
                    {t("Doctype")}
                </h3>

                {isHTML5Doctype ? (
                    <p className="analysis-text-success">
                        {t("DoctypeCorrect")}
                    </p>
                ) : (
                    <p className="analysis-text-error">
                        {t("DoctypeIncorrect")}
                    </p>
                )}
            </div>
            
            <div className="analysis-result-item">
                <h3 className="mb-1 font-medium text-xl">
                    {t("HTML5")}
                </h3>

                {hasHTML5Elements ? (
                    <p className="analysis-text-success">
                        {t("HTML5Correct")}
                    </p>
                ) : (
                    <p className="analysis-text-error">
                        {t("HTML5Incorrect")}
                    </p>
                )}
            </div>
            
            <div className="analysis-result-item">
                <h3 className="mb-1 font-medium text-xl">
                    {t("DeprecatedTags")}
                </h3>

                {deprecatedTags?.length === 0 ? (
                    <p className="analysis-text-success">
                        {t("DeprecatedTagsCorrect")}
                    </p>
                ) : (
                    <p className="analysis-text-error">
                        {t("DeprecatedTagsIncorrect").replace("%tags%", deprecatedTags?.join(", "))}
                    </p>
                )}
            </div>

            <div className="analysis-result-item">
                <h3 className="mb-1 font-medium text-xl">
                    {t("HTMLLang")}
                </h3>

                <ul>
                    {hasLangAttribute ? (
                        <li className="analysis-text-success">
                            {t("HTMLLangFound")}
                        </li>
                    ) : (
                        <li className="analysis-text-error">
                            {t("LangAttributeIncorrect")}
                        </li>
                    )}

                    {isValidLang ? (
                        <li className="analysis-text-success">
                            {t("HTMLLangValid")}
                        </li>
                    ) : (
                        <li className="analysis-text-error">
                            {t("HTMLLangInvalid")}
                        </li>
                    )}
                </ul>
                
            </div>
            
            <div className="analysis-result-item">
                <h3 className="mb-1 font-medium text-xl">
                    {t("LinksDescriptiveText")}
                </h3>

                {nonDescriptiveAnchors?.length === 0 ? (
                    <p className="analysis-text-success">
                        {t("LinksDescriptiveTextCorrect")}
                    </p>
                ) : (
                    <div className="analysis-text-error">
                        <p>
                            {t("LinksDescriptiveTextIncorrect")}
                        </p>
                        <ul>
                            {nonDescriptiveAnchors?.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
            
            <div className="analysis-result-item">
                <h3 className="mb-1 font-medium text-xl">
                    {t("LinksTitle")}
                </h3>

                {anchorsWithoutTitle?.length === 0 ? (
                    <p className="analysis-text-success">
                        {t("LinksTitleCorrect")}
                    </p>
                ) : (
                    <div className="analysis-text-error">
                        <p>
                            {t("LinksTitleIncorrect")}
                        </p>
                        <ul>
                            {anchorsWithoutTitle?.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>

            <div className="analysis-result-item">
                <h3 className="mb-1 font-medium text-xl">
                    {t("HeadingOrder")}
                </h3>

                {isHeadingOrdered ? (
                    <p className="analysis-text-success">
                        {t("HeadingOrderCorrect")}
                    </p>
                ) : (
                    <p className="analysis-text-error">
                        {headingCounts?.h1 > 0 ? t("HeadingOrderIncorrect") : t("Heading1Missing")}
                    </p>
                )}

                <div className="analysis-result-tags">
                    {headingCounts && Object.keys(headingCounts).map((htag, index) => (
                        <div key={index}>{htag} - {headingCounts[htag]}</div>
                    ))}
                </div>
            </div>
            
            <div className="analysis-result-item">
                <h3 className="mb-1 font-medium text-xl">
                    {t("Favicon")}
                </h3>

                {hasFavicon ? (
                    <p className="analysis-text-success">
                        {t("FaviconCorrect")}
                    </p>
                ) : (
                    <p className="analysis-text-error">
                        {t("FaviconIncorrect")}
                    </p>
                )}
            </div>
            
            <div className="analysis-result-item">
                <h3 className="mb-1 font-medium text-xl">
                    {t("MixedContents")}
                </h3>

                {hasSecureResources ? (
                    <p className="analysis-text-success">
                        {t("MixedContentsCorrect")}
                    </p>
                ) : (
                    <p className="analysis-text-error">
                        {t("MixedContentsIncorrect")}
                    </p>
                )}
            </div>
                
            <div className="analysis-result-item">
                <h3 className="mb-1 font-medium text-xl">
                    {t("SocialTags")}
                </h3>

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 py-2">
                    {socialTags.twitter && (
                        <div className="analysis-box space-y-1 py-4 px-6">
                            <h4 className="font-semibold text-lg">Twitter</h4>
                            <div>
                                <h5 className="font-medium">{t("Title")}</h5>
                                <p>{socialTags.twitter.title}</p>
                            </div>
                            <div>
                                <h5 className="font-medium">{t("Description")}</h5>
                                <p>{socialTags.twitter.description}</p>
                            </div>
                        </div>
                    )}

                    {socialTags.openGraph && (
                        <div className="analysis-box space-y-1 py-4 px-6">
                            <h4 className="font-semibold text-lg">OpenGraph</h4>
                            <div>
                                <h5 className="font-medium">{t("Title")}</h5>
                                <p>{socialTags.openGraph.title}</p>
                            </div>
                            <div>
                                <h5 className="font-medium">{t("Description")}</h5>
                                <p>{socialTags.openGraph.description}</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className="analysis-result-item">
                <h3 className="mb-1 font-medium text-xl">
                    {t("ImagesAlt")}
                </h3>

                {hasImagesAlt ? (
                    <p className="analysis-text-success">
                        {t("ImagesAltCorrect")}
                    </p>
                ) : (
                    <p className="analysis-text-error">
                        {t("ImagesAltIncorrect")}
                    </p>
                )}
            </div>
        </section>
    );
}

export default AnalysisHtml;
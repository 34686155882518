import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import scoreIC from "../assets/scrore-ic.svg";
import keyIC from "../assets/key-ic.svg";
import suggestionIC from "../assets/suggestion-ic.svg";
import scoreImg from "../assets/score.png";
import keywordsImg from "../assets/keywords.png";
import advicesImg from "../assets/advices.png";
import scoreMobileImg from "../assets/score-mobile.png";
import keywordsMobileImg from "../assets/keywords-mobile.png";
import adviceMobileImg from "../assets/advice-mobile.png";

function FeaturesBanner() {
    const { t } = useTranslation();

    return (
        <section className='py-12 overflow-hidden'>
            <div className='container-def px-6'>
                <h1 className='font-bold text-3xl sm:text-4xl pt-6'>
                    {t("Features")}
                </h1>

                {/* SM/MD SCREENS */}
                <div className="flex flex-col sm:flex-row gap-10 lg:hidden pt-12">
                    <div className="space-y-2">
                        <figure>
                            <img src={scoreMobileImg} alt="Score"/>
                        </figure>
                        <h2 className='flex font-semibold gap-2 items-center text-2xl'>
                            <ReactSVG src={scoreIC}/>
                            <span>
                                {t("Score")}
                            </span>
                        </h2>
                        <p>
                            {t("ScoreCaption")}
                        </p>
                    </div>
                    <div className="space-y-2">
                        <figure>
                            <img src={keywordsMobileImg} alt="Keywords"/>
                        </figure>
                        <h2 className='flex font-semibold gap-2 items-center text-2xl'>
                            <ReactSVG src={keyIC}/>
                            <span>
                                {t("Keywords")}
                            </span>
                        </h2>
                        <p className="py-3">
                            {t("KeywordsCaption")}
                        </p>
                    </div>
                    <div className="space-y-2">
                        <figure>
                            <img src={adviceMobileImg} alt="Advice"/>
                        </figure>
                        <h2 className='flex font-semibold gap-2 items-center text-2xl'>
                            <ReactSVG src={suggestionIC}/>
                            <span>
                                {t("Advices")}
                            </span>
                        </h2>
                        <p className="py-3">
                            {t("AdvicesCaption")}
                        </p>
                    </div>
                </div>

                {/* LG SCREENS */}
                <Tabs className="hidden lg:flex flex-row gap-12">
                    <div className='basis-1/3 relative z-10'>
                        <TabList className="mt-12 flex flex-col gap-6">
                            <Tab className="tab-box tab-box-blue">
                                <h2 className='flex font-semibold gap-2 items-center text-2xl'>
                                    <ReactSVG src={scoreIC}/>
                                    <span>
                                        {t("Score")}
                                    </span>
                                </h2>
                                <p className="py-3">
                                    {t("ScoreCaption")}
                                </p>
                            </Tab>
                            <Tab className='tab-box tab-box-pink'>
                                <h2 className='flex font-semibold gap-2 items-center text-2xl'>
                                    <ReactSVG src={keyIC}/>
                                    <span>
                                        {t("Keywords")}
                                    </span>
                                </h2>
                                <p className="py-3">
                                    {t("KeywordsCaption")}
                                </p>
                            </Tab>
                            <Tab className='tab-box tab-box-fucsia'>
                                <h2 className='flex font-semibold gap-2 items-center text-2xl'>
                                    <ReactSVG src={suggestionIC}/>
                                    <span>
                                        {t("Advices")}
                                    </span>
                                </h2>
                                <p className="py-3">
                                    {t("AdvicesCaption")}
                                </p>
                            </Tab>
                        </TabList>
                    </div>

                    <div className="flex-1 mt-10 relative">
                        <TabPanel>
                            <div className="feature-banner-image">
                                <img src={scoreImg} alt="Score"/>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="feature-banner-image">
                                <img src={keywordsImg} alt="Score"/>
                            </div>
                        </TabPanel>
                            
                        <TabPanel>
                        <div className="feature-banner-image">
                                <img src={advicesImg} alt="Score"/>
                            </div>
                        </TabPanel>
                    </div>
                </Tabs>
            </div>
        </section>
    );
}

export default FeaturesBanner;
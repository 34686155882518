import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import { useGlobalContext } from "../../context/Context";
import MainLayout from "../../components/MainLayout";
import backBtnIcon from "../../assets/back-arrow-ic.svg";

function ChangePasswordPage() {
    const { authClient, authReady, loggedUser } = useGlobalContext();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const navigate = useNavigate();

    const submitHandler = (event) => {
        const data = new FormData(event.target);
        const fields = Object.fromEntries(data.entries());
        event.preventDefault();

        if (!fields.currentPassword || !fields.newPassword || fields.newPassword !== fields.passwordConfirmation) {
            setError("IncorrectPassword");
            return;
        }

        if (!loggedUser) {
            setError("AuthRequired");
            return;
        }

        if (!loading) {
            setLoading(true);

            authClient.changePassword(fields.currentPassword, fields.newPassword).then(result => {
                setLoading(false);

                if (!result.success) {
                    setError(result.message);
                    return;
                }

                navigate("/account", {
                    state: { message: "PasswordUpdated" },
                });
            });
        }
    }

    useEffect(() => {
        if (authReady && !loggedUser) {
            navigate("/");
        }
    });

    return (
        <MainLayout title={t("ChangePassword")}>
            <section className='container-def px-6 py-12'>
                <div className='flex flex-col sm:flex-row sm:gap-12'>
                    <Link to="/account">
                        <ReactSVG src={backBtnIcon} title="Back"/>
                    </Link>

                    <div className="basis-1/2 flex flex-col gap-6">
                        <div className="text-center sm:text-start">
                            <h1 className="font-bold mb-6 text-2xl sm:text-4xl">
                                {t('ChangePassword')}
                            </h1>
                        </div>

                        {error && (
                            <p className="text-red-500">
                                {t(error)}
                            </p>
                        )}

                        <form className="contact-form max-w-sm sm:max-w-full mx-auto sm:mx-0 text-sm sm:text-base" onSubmit={submitHandler}>
                            <label>
                                <span>
                                    {t('CurrentPassword')}
                                </span>
                                <input type="password" name="currentPassword" required/>
                            </label>
                            <label>
                                <span>
                                    {t('NewPassword')}
                                </span>
                                <input type="password" name="newPassword" required/>
                            </label>
                            <label>
                                <span>
                                    {t('ConfirmPassword')}
                                </span>
                                <input type="password" name="passwordConfirmation" required/>
                            </label>

                            <div className="contact-form-options">
                                <button type="submit" className='btn btn-primary rounded-lg' disabled={loading}>
                                    <span className='block font-bold m-1'>
                                        {t('ChangePassword')}
                                    </span>
                                </button>
                                <Link to="/account">
                                    {t('Cancel')}
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </MainLayout>
    );
}

export default ChangePasswordPage;
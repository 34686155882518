import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { ReactSVG } from "react-svg";
import DialogOpen from "../../components/DialogOpen";
import closeIc from '../../assets/x-ic.svg';

function AnalysisTextSuggestions({ texts = [], isInProgress = false, onTextUnlock }) {
    const { t } = useTranslation();
    const [tabIndex, setTabIndex] = useState(0);
    const [showNewTextDialog, setShowNewTextDialog] = useState(false);

    const closeNewTextDialog = () => {
        setShowNewTextDialog(false);
    }

    const getFormattedKeywords = (keyword) => {
        const array = [];

        if (keyword) {
            keyword.split("\n").forEach(string => {
                const newString = string.trim().replace("- ", "");
                array.push(newString);
            });
        }

        return array.join(", ");
    }

    const unlockText = () => {
        if (isInProgress) {
            return;
        }

        if (!showNewTextDialog) {
            setShowNewTextDialog(true);
            return;
        }

        if (typeof onTextUnlock === "function") {
            setShowNewTextDialog(false);
            onTextUnlock();
        }
    }

    return (
        <section id="textsuggestions">
            <h2 className="font-semibold text-2xl text-fucsia-def">
                {t("TextSuggestions")}
            </h2>

            <Tabs tabIndex={tabIndex} onSelect={setTabIndex} className="flex flex-col sm:flex-row gap-6 mt-6">
                <div className="sm:w-56">
                    <TabList>
                        {[...Array(texts.length)].map((_, index) => (
                            <Tab className="analysis-box flex flex-col p-6 text-center mb-6" key={index}>
                                <span className="font-semibold">
                                    {t("TextSuggestion").replace("%i%", index + 1)}
                                </span>
                                <button className="btn btn-lightblue font-semibold mt-6 rounded-lg" disabled={tabIndex === index}>
                                    {t("Show")}
                                </button>
                            </Tab>
                        ))}
                    </TabList>
                    
                    {texts.length < 3 && (
                        <div className="analysis-box flex flex-col p-6 text-center">
                            <span className="font-semibold">
                                {t("TextSuggestion").replace("%i%", texts.length + 1)}
                            </span>
                            <button onClick={unlockText} disabled={isInProgress} className="btn btn-fucsia font-semibold mt-6 rounded-lg">
                                {isInProgress ? t("Loading") : t("Unlock")}
                            </button>
                        </div>
                    )}
                </div>

                <div className="flex-1">
                    {texts.map((item, index) => (
                        <TabPanel key={index}>
                            <div className="analysis-box px-6 py-4 space-y-4">
                                <div>
                                    <h3 className="mb-3 font-semibold text-xl text-fucsia-def">
                                        {t("Suggestion")} {index + 1}
                                    </h3>
                                    <p className="cursor-text">{item.body}</p>
                                </div>
                                <div>
                                    <h3 className="mb-3 font-semibold text-xl text-fucsia-def">
                                        {t("NewKeywords")}
                                    </h3>
                                    <p>
                                        {t("NewKeywordsIntro")}
                                    </p>
                                    <p className="font-semibold">{getFormattedKeywords(item.keywords)}</p>
                                </div>
                            </div>
                        </TabPanel>
                    ))}
                </div>
            </Tabs>

            {showNewTextDialog && (
                <DialogOpen>
                    <button type="button" onClick={closeNewTextDialog} className="dialog-close-btn">
                        <ReactSVG src={closeIc} title="Close"/>
                    </button>
                    <div className="dialog-body">
                        <h2 className="font-medium py-3 px-6 text-center text-lg">
                            {t("GenerateTextDialogTitle")}
                        </h2>
                    </div>
                    <div className="dialog-options border-t text-sm">
                        <button onClick={closeNewTextDialog}>
                            {t("Cancel")}
                        </button>
                        <button onClick={unlockText} className="btn btn-primary">
                            <span className="block font-semibold py-1">
                                {t("Confirm")}
                            </span>
                        </button>
                    </div>
                </DialogOpen>
            )}
        </section>
    );
}

export default AnalysisTextSuggestions;
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import MainLayout from "../../components/MainLayout";
import backBtnIcon from "../../assets/back-arrow-ic.svg";
import arrowLeft from "../../assets/arrow-left-fill.svg";
import arrowRight from "../../assets/arrow-right-fill.svg";

function OrderHistoryPage() {
    const { t } = useTranslation();

    return (
        <MainLayout title={t("OrderHistory")}>
            <section className='container-def px-6 py-12'>
                <div className='flex flex-col sm:flex-row sm:gap-12 relative'>
                    <div className="basis-14">
                        <Link to="/account" className="basis-14">
                            <ReactSVG src={backBtnIcon} title="Back"/>
                        </Link>
                    </div>
                    <div className="flex flex-1 flex-col gap-6">
                        <h1 className="font-bold text-2xl sm:text-4xl text-center sm:text-start">
                            {t('BillingHistory')}
                        </h1>

                        <div className="overflow-x-auto">
                            <table className="order-history-table text-xs sm:text-sm">
                                <thead>
                                    <tr>
                                        <th>{t('IssuingDate')}</th>
                                        <th>{t('Description')}</th>
                                        <th>{t('Amount')}</th>
                                        <th>{t('State')}</th>
                                        <th>{t('Invoice')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>YYYY/MM/DD</td>
                                        <td>
                                            {t('StandardPlan')}
                                        </td>
                                        <td>€200</td>
                                        <td>
                                        <span className="payment-state-circle" aria-label="payed"></span>
                                            {t('Payed')}
                                        </td>
                                        <td>
                                            <button className="underline">
                                                {t('Details')}
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>YYYY/MM/DD</td>
                                        <td>
                                            {t('StandardPlan')}
                                        </td>
                                        <td>€200</td>
                                        <td>
                                            <span className="payment-state-circle" aria-label="payed"></span>
                                            {t('Payed')}
                                        </td>
                                        <td>
                                            <button className="underline">
                                                {t('Details')}
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <nav className="ms-auto space-x-6 text-fucsia-def">
                            <button type="button" className="btn" disabled>
                                <ReactSVG src={arrowLeft} title="Back"/>
                            </button>
                            <button type="button" className="btn" disabled>
                                <ReactSVG src={arrowRight} title="Next"/>
                            </button>
                        </nav>
                    </div>
                </div>
            </section>
        </MainLayout>
    );
}

export default OrderHistoryPage;
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { useTranslation } from "react-i18next"
import { useGlobalContext } from "../../context/Context";
import MainLayout from "../../components/MainLayout";
import backBtnIcon from "../../assets/back-arrow-ic.svg";
import loadingGif from "../../assets/loading.gif";
import AnalysisMetaTag from "../../sections/analysis/AnalysisMetaTag";
import AnalysisHtml from "../../sections/analysis/AnalysisHtml";
import AnalysisUrl from "../../sections/analysis/AnalysisUrl";
import AnalysisTextSuggestions from "../../sections/analysis/AnalysisTextSuggestions";
import DialogOpen from "../../components/DialogOpen";
import closeIc from "../../assets/x-ic.svg";
import AnalysisContent from "../../sections/analysis/AnalysisContent";

function AnalysisShowPage() {
    const { apiClient, authReady } = useGlobalContext();
    const { t } = useTranslation();
    const { id } = useParams();
    const [analysisData, setAnalysisData] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [showRenameDialog, setShowRenameDialog] = useState(false);
    const [nameField, setNameField] = useState("");
    const [wsFailed, setWsFailed] = useState(false);
    const isPending = loading || analysisData?.status === 0;
    const analysisresult = analysisData?.result;
    const texts = analysisData?.texts;

    const url = useMemo(() => {
        if (!analysisData) {
            return null;
        }
        
        if (analysisData.files.length > 0) {
            return analysisData.files[0].originalname;
        }

        return analysisData.url;
    }, [analysisData]);

    const fetchAnalysis = useCallback(() => {
        setLoading(true);

        apiClient.showAnalysis(id).then(result => {
            setLoading(false);

            if (!result.success || !result.response) {
                setError(result.message);
                return;
            }

            const data = result.response.data;
            localStorage.setItem("lastAnalysisData", JSON.stringify({ id, ...data }));
            setAnalysisData(data);

            if (data && data.name) {
                setNameField(data.name);
            }
        });
    }, [apiClient, id]);

    const repeatAnalysis = () => {
        if (!loading) {
            setLoading(true);

            apiClient.repeatAnalysis(id).then(result => {
                setLoading(false);

                if (!result.success || !result.response) {
                    setError(result.message);
                    return;
                }

                setAnalysisData(result.response.data);
            });
        }
    }

    const renameSubmitHandler = (event) => {
        event.preventDefault();
        
        if (!loading) {
            setShowRenameDialog(false);
            setLoading(true);

            apiClient.renameAnalysis(id, nameField).then(result => {
                setLoading(false);

                if (!result.success) {
                    setError(result.message);
                    return;
                }

                fetchAnalysis();
            });
        }
    }

    useEffect(() => {
        if (!analysisData) {
            const lastAnalysisData = JSON.parse(localStorage.getItem("lastAnalysisData"));
    
            if (lastAnalysisData && lastAnalysisData.id === id) {
                setAnalysisData(lastAnalysisData);

                if (lastAnalysisData.name) {
                    setNameField(lastAnalysisData.name);
                }
            }
        }
    }, [analysisData, id]);

    useEffect(() => {
        let ws;

        if (authReady) {
            ws = apiClient.openWebSocket();

            ws.addEventListener("message", (event) => {
                const message = JSON.parse(event.data);

                if (message.analysisId === id) {
                    fetchAnalysis();
                }
            });

            ws.addEventListener("error", () => {
                setWsFailed(true);
            });
            
            fetchAnalysis();
        }

        return () => {
            ws?.close();
        }
    }, [apiClient, authReady, fetchAnalysis, id]);

    return (
        <MainLayout title={t("Analyses")}>
            <div className="container-def py-12 px-6">
                <div className="flex flex-col sm:flex-row sm:gap-12 pb-12">
                    <Link to="/account/analysis">
                        <ReactSVG src={backBtnIcon} title="Back"/>
                    </Link>
                    <div className="flex-1 text-center sm:text-start">
                        <h1 className="font-bold text-2xl sm:text-4xl">
                            {t("Analysis")}
                        </h1>
                    </div>
                </div>
                
                {error && (
                    <div className="box mb-6 px-3 py-2 font-medium">
                        {t(error)}
                    </div>
                )}

                {wsFailed && (
                    <div className="box mb-6 px-3 py-2 font-medium">
                        {t("ConnectionLost")}
                    </div>
                )}

                {analysisData?.status === 2 && (
                    <div className="box mb-6 px-3 py-2 font-medium">
                        {t(analysisData.message)}
                    </div>
                )}

                {analysisresult && (
                    <div className="space-y-6">
                        <AnalysisContent
                            name={analysisData.name}
                            rating={analysisresult.rating}
                            wordCount={analysisresult.wordCount}
                            textAnalysis={analysisresult.textAnalysis}
                            keywords={analysisresult.keywords}
                            url={url}
                            showRename={() => setShowRenameDialog(true)}
                        />

                        <AnalysisMetaTag
                            title={analysisresult.title}
                            metadescription={analysisresult.metadescription}
                            keywords={analysisresult.keywords}
                        />

                        {analysisresult.htmlAnalysis && (
                            <AnalysisHtml htmlAnalysis={analysisresult.htmlAnalysis}/>
                        )}

                        {analysisresult.urlAnalysis?.fromUrl && (
                            <AnalysisUrl urlAnalysis={analysisresult.urlAnalysis}/>
                        )}
                        
                        {texts && texts.length > 0 && (
                            <AnalysisTextSuggestions
                                texts={texts}
                                isInProgress={isPending}
                                onTextUnlock={repeatAnalysis}
                            />
                        )}
                    </div>
                )}

                {isPending && !analysisresult && (
                    <div className="analysis-box bg-white flex flex-col justify-center items-center mt-6 p-6">
                        <img src={loadingGif} alt="Loading" className="max-w-20"/>
                        <p className="mt-2">{t("AnalysisInProgressCaption")}</p>
                    </div>
                )}

                {showRenameDialog && (
                    <DialogOpen>
                        <button type="button" onClick={() => setShowRenameDialog(false)} className="dialog-close-btn">
                            <ReactSVG src={closeIc} title="Close"/>
                        </button>
                        <form onSubmit={renameSubmitHandler}>
                            <div className="dialog-body max-w-lg w-screen">
                                <label className="analyze-configuration-label">
                                    <h2 className="font-medium py-3 px-6 text-center text-lg">
                                        {t("RenameAnalysis")}
                                    </h2>
                                    <input 
                                        type="text"
                                        name="name"
                                        value={nameField}
                                        onChange={(event) => setNameField(event.target.value)}
                                    />
                                </label>
                            </div>
                            <div className="dialog-options border-t text-sm">
                                <button type="button" onClick={() => setShowRenameDialog(false)}>
                                    {t("Cancel")}
                                </button>
                                <button type="submit" className="btn btn-primary">
                                    <span className="block font-semibold py-1">
                                        {t("Confirm")}
                                    </span>
                                </button>
                            </div>
                        </form>
                    </DialogOpen>
                )}
            </div>
        </MainLayout>
    );
}

export default AnalysisShowPage
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import { useGlobalContext } from "../context/Context";
import useMultistepForm from "../hooks/useMultistepForm";
import UploadStep from "./analyze/UploadStep";
import ConfigureStep from "./analyze/ConfigureStep";
import AnalysisFormData from "./analyze/AnalysisFormData";
import xCircleIc from "../assets/x-circle.svg";

function AnalyzeForm({ close }) {
    const { apiClient, loggedUser, switchModal } = useGlobalContext();
    const { t, i18n } = useTranslation();
    const [isFromShortcut, setIsFromShortcut] = useState(false);
    const [data, setData] = useState(AnalysisFormData.create());
    const [error, setError] = useState();
    
    const { currentStepIndex, step, isFirstStep, isLastStep, back, next } = useMultistepForm([
        <UploadStep key={0} data={data} setData={setData} />,
        <ConfigureStep key={1} data={data} setData={setData} />,
    ]);
    
    const navigate = useNavigate();

    const sendData = () => {
        const formData = new FormData();
        const opt = JSON.stringify(data);

        data.userFiles.forEach(file => {
            formData.append("userFiles[]", file);
        });

        formData.append("opt", opt);
        formData.append("lang", i18n.language);

        apiClient.storeAnalysis(formData).then(result => {
            if (!result.success) {
                setError(result.message);
                console.error("Failed to store", result);
                return;
            }

            const data = {
                id: result.response.id,
                ...result.response.data
            }

            localStorage.setItem("lastAnalysisData", JSON.stringify(data));
            navigate(`/account/analysis/${data.id}`);
        });
    }

    const resetHandler = (event) => {
        event.preventDefault();

        if (isFirstStep || (isFromShortcut && currentStepIndex === 1)) {
            if (typeof close === "function") {
                close();
            }
            return;
        }

        setError(null);
        back();
    }

    const submitHandler = (event) => {
        event.preventDefault();

        if (!isLastStep) {
            next();
            return;
        }

        if (!loggedUser) {
            switchModal("register");
            return;
        }

        sendData();
    }

    useEffect(() => {
        const shortcutWebUrl = sessionStorage.getItem("shortcutWebUrl");
        
        if (shortcutWebUrl) {
            setData(prev => ({
                ...prev,
                inputMode: AnalysisFormData.INPUT_URL,
                websiteUrl: shortcutWebUrl,
            }));
            
            next();
            setIsFromShortcut(true);
            sessionStorage.removeItem("shortcutWebUrl");
        }
    }, [next]);

    return (
        <form onSubmit={submitHandler} onReset={resetHandler} encType="multipart/form-data" method="POST">
            {error && (
                <div className="px-6 pt-6 text-center">
                    <div className="bg-red-800 inline-flex gap-3 justify-center items-center p-3 rounded-lg">
                        <ReactSVG src={xCircleIc}/>
                        <span className="font-medium text-white">
                            {t(error)}
                        </span>
                    </div>
                </div>
            )}

            {step}
        </form>
    );
}

export default AnalyzeForm;
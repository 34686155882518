import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next"
import { ReactSVG } from "react-svg";
import { Link } from "react-router-dom";
import { useGlobalContext } from "../../context/Context";
import MainLayout from "../../components/MainLayout"
import plusIc from "../../assets/fluent_add-20-filled.svg";
import closeIc from "../../assets/x-ic.svg";
import AnalyzeForm from "../../sections/AnalyzeForm";
import DialogOpen from "../../components/DialogOpen";
import useAnalysisTools from "../../hooks/useAnalysisTools";

function AnalysisListPage() {
    const { apiClient, authReady } = useGlobalContext();
    const { t } = useTranslation();
    const { getDateString } = useAnalysisTools();
    const [showAnalyze, setShowAnalyze] = useState(false);
    const [analysisList, setAnalysisList] = useState([]);
    const [error, setError] = useState();

    useEffect(() => {
        const sessionAnalysis = JSON.parse(sessionStorage.getItem("analysisList"));

        if (sessionAnalysis && Array.isArray(sessionAnalysis)) {
            setAnalysisList(sessionAnalysis);
        }

        if (authReady) {
            apiClient.indexAnalysis().then(result => {
                if (!result.success) {
                    setError(result.message);
                    return;
                }

                const analysis = result.response.docs;
                setAnalysisList(analysis);
                sessionStorage.setItem("analysisList", JSON.stringify(analysis));
            });
        }
    }, [apiClient, authReady]);

    return (
        <MainLayout title={t("Analyses")}>
            <section className='container-def py-12'>
                <div className="px-6">
                    <div className="flex flex-wrap gap-3 items-center pb-12">
                        <h1 className="font-medium text-center text-3xl">
                            {t("YourAnalysis")}
                        </h1>
                        <button type="button" onClick={() => setShowAnalyze(true)} className="btn btn-fucsia ms-auto rounded-lg">
                            <div className="flex flex-row items-center gap-2">
                                <span className="font-medium">
                                    {t("NewAnalysis")}
                                </span>
                                <ReactSVG src={plusIc}/>
                            </div>
                        </button>
                    </div>

                    {error && (
                        <div className="box mb-6 px-3 py-2 font-medium">
                            {t(error)}
                        </div>
                    )}
                </div>

                <div className="s-wrapper">
                    {analysisList.map((doc, index) => (
                        <div key={index} className="box overflow-hidden">
                            <div className="p-3 space-y-1">
                                <h2 className="text-xl">
                                    {doc.data.name ?? doc.id}
                                </h2>
                                <p className="text-sm">
                                    {getDateString(doc.data.createdAt)}
                                </p>
                                <div className="flex flex-wrap justify-between items-center">
                                    {doc.data.status === 0 && (
                                        <span className="text-orange-500">
                                            {t("AnalysisInProgress")}
                                        </span>
                                    )}

                                    {doc.data.status === 2 && (
                                        <span className="text-red-500">
                                            {t("AnalysisError")}
                                        </span>
                                    )}

                                    <Link to={`/account/analysis/${doc.id}`} className="btn btn-fucsia font-medium ms-auto rounded-lg">
                                        {t("ShowMore")}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            {showAnalyze && (
                <DialogOpen>
                    <button type="button" onClick={() => setShowAnalyze(false)} className="dialog-close-btn">
                        <ReactSVG src={closeIc} title="Close"/>
                    </button>
                    <div className="dialog-analyze">
                        <AnalyzeForm />
                    </div>
                </DialogOpen>
            )}
        </MainLayout>
    )
}

export default AnalysisListPage
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import MainLayout from "../../components/MainLayout";

function CookieDeclarationPage() {
    const { t } = useTranslation();
    const containerRef = useRef();

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://consent.cookiebot.com/e1da220e-6f34-46b3-9f50-f8e213db2ca8/cd.js";
        script.type = "text/javascript";
        script.async = true;

        if (containerRef.current) {
            containerRef.current.appendChild(script);
        }

        return () => {
            script.remove();
        };
    }, []);

    return (
        <MainLayout title={t("CookieDeclaration")}>
            <section className="container-def py-12 px-6">
                <h1 className="font-medium text-2xl sm:text-3xl">
                    {t("CookieDeclaration")}
                </h1>
                <div className="pt-12" ref={containerRef}></div>
            </section>
        </MainLayout>
    );
}

export default CookieDeclarationPage;
import { useEffect } from 'react';
import { useGlobalContext } from '../context/Context';
import ForgotPassModal from '../sections/modals/ForgotPassModal';
import LoginModal from '../sections/modals/LoginModal';
import RegisterModal from '../sections/modals/RegisterModal';
import VerificationSentModal from '../sections/modals/VerificationSentModal';

function ModalListener() {
    const { modalStates } = useGlobalContext();

    useEffect(() => {
        const modalVisible = Object.values(modalStates).some(ms => ms);
        document.body.classList.toggle("modal-open", modalVisible);
    }, [modalStates]);

  return (
      <>
          { modalStates.login && <LoginModal/> }
          { modalStates.register && <RegisterModal/> }
          { modalStates.forgotPass && <ForgotPassModal/> }
          { modalStates.verificationSent && <VerificationSentModal/> }
      </>
  );
}

export default ModalListener;
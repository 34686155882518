import { useTranslation } from "react-i18next";
import Popover from "../../components/Popover";

function AnalysisMetaTag({ title, metadescription, keywords }) {
    const { t } = useTranslation();

    return (
        <section>
            <h2 className="font-semibold text-2xl text-fucsia-def">
                {t("MetaTag")}
            </h2>

            {title && (
                <div className="analysis-result-item">
                    <div className="analysis-result-title">
                        <h3 className="mb-1 font-medium text-xl">
                            {t("Title")}
                        </h3>
                        <Popover>
                            {t("TitleDescription")}
                        </Popover>
                    </div>

                    <p>
                        {title.titleAnalysis}
                    </p>

                    <div className="banner-fucsia mt-3 p-3 rounded-lg space-x-1">
                        <span className="font-medium text-fucsia-def">
                            {t("SuggestedTitle")}:
                        </span>
                        <span>
                            {title.correctTitle}
                        </span>
                    </div>
                </div>
            )}

            {metadescription && (
                <div className="analysis-result-item">
                    <div className="analysis-result-title">
                        <h3 className="mb-1 font-medium text-xl">
                            {t("Description")}
                        </h3>
                        <Popover>
                            {t("DescriptionDescription")}
                        </Popover>
                    </div>

                    {metadescription.metatagAnalysis === "MissingMetaDescription" ? (
                        <p className="font-medium mt-1 analysis-text-error">
                            {t("MissingMetaDescription")}
                        </p>
                    ) : (
                        <>
                            <p className="mt-1">
                                {metadescription.metatagAnalysis}
                            </p>

                            <div className="banner-fucsia mt-3 p-3 rounded-lg space-x-1">
                                <span className="font-medium text-fucsia-def">
                                    {t("SuggestedDescription")}:
                                </span>
                                <span>
                                    {metadescription.correctMetatag}
                                </span>
                            </div>
                        </>
                    )}
                </div>
            )}

            {keywords && (
                <div className="analysis-result-item">
                    <h3 className="mb-1 font-medium text-xl">
                        {t("Keywords")}
                    </h3>

                    {keywords.keywordMetatagAnalysis === "MissingMetaKeywords" ? (
                        <p className="font-medium mt-1 analysis-text-error">
                            {t("MissingMetaKeywords")}
                        </p>
                    ) : (
                        <p className="mt-1">
                            {keywords.keywordMetatagAnalysis}
                        </p>
                    )}
                </div>
            )}
        </section>
    );
}

export default AnalysisMetaTag;
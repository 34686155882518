import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import MainLayout from '../components/MainLayout';
import PricingBox from '../components/PricingBox';
import BoxWrapper from '../components/BoxWrapper';

function PricingPage() {
    const { t } = useTranslation();
    const [tabIndex, setTabIndex] = useState(0);

    return (
        <MainLayout title={t("Pricing")}>
            <section className='container-def pricing-section'>
                <div className='font-medium py-12 px-3 text-center'>
                    <h1 className='mb-3 font-semibold text-3xl sm:text-4xl'>
                        {tabIndex === 0 ? t('PricingTitle') : t('PackagesTitle')}
                    </h1>
                    <h2 className='text-lg sm:text-xl'>
                        {tabIndex === 0 ? t('PricingSubtitle') : t('PackagesSubtitle')}
                    </h2>
                </div>

                <Tabs selectedIndex={tabIndex} onSelect={setTabIndex}>
                    <div className='flex justify-center pb-16 md:pb-20 px-6'>
                        <TabList className='rounded-tab-list'>
                            <Tab>{t("Subscriptions")}</Tab>
                            <Tab>{t("Packages")}</Tab>
                        </TabList>
                    </div>

                    <TabPanel>
                        <BoxWrapper sliderRepeat={true} sliderStartPos={0}>
                            <PricingBox name={t('BasicPlan')} price={`50€/${t('month')}`} credits={10} description={t('BasePlanCaption')}/>
                            <PricingBox name={t('StandardPlan')} price={`200€/${t('month')}`} credits={50} description={t('StandardPlanCaption')} bestMark={true}/>
                            <PricingBox name={t('EnterprisePlan')} price={`400€/${t('month')}`} credits={120} description={t('EnterprisePlanCaption')}/>
                        </BoxWrapper>
                    </TabPanel>

                    <TabPanel>
                        <BoxWrapper sliderRepeat={true} sliderStartPos={0}>
                            <PricingBox name={"Small"} price={`30€`} credits={5} iconCount={1}/>
                            <PricingBox name={"Medium"} price={`110€`} credits={20} iconCount={2}/>
                            <PricingBox name={"Large"} price={`300€`} credits={80} iconCount={3}/>
                        </BoxWrapper>
                        <p className='text-center pt-12'>
                            {t('CreditPacketCaption')}
                        </p>
                    </TabPanel>
                </Tabs>
            </section>
        </MainLayout>
    );
};

export default PricingPage;
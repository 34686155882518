import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import MainLayout from '../components/MainLayout';
import coverBg from "../assets/about-bg.svg";
import aboutWords from "../assets/about-words.svg";
import teamFrameImg from "../assets/Frame263.png";
import missionArrow1 from "../assets/mission-arrow-1.svg";
import missionArrow2 from "../assets/mission-arrow-2.svg";
import missionBg from "../assets/mission-bg.png";

function AboutPage() { 
    const { t } = useTranslation();

    return (
        <MainLayout title={t("About")}>
            <section className='pt-12 overflow-x-hidden'>
                <div className='max-w-7xl mx-auto px-6 relative text-center'>
                    <div className='max-w-4xl mx-auto'>
                        <h1 className='font-semibold text-center text-3xl sm:text-5xl'>
                            {t('AboutTitle')}
                        </h1>
                        <p className='mt-6 sm:mt-12 text-gray-def text-lg sm:text-xl'>
                            {t("AboutCaption")}
                        </p>
                    </div>
                    <div className='flex justify-center items-center mt-6 overflow-x-visible w-full'>
                        <img src={aboutWords} alt='Keywords' className='about-words'/>
                    </div>
                </div>
                <div className='overflow-hidden'>
                    <div className='bg-cover bg-top h-28 sm:h-52 top-8 relative' style={{backgroundImage: `url(${coverBg})`}}></div>
                </div>
            </section>

            <section className='banner-fucsia py-12'>
                <div className='container-def px-6'>
                    <h2 className='font-semibold text-center text-3xl sm:text-4xl'>
                        {t('OurTeam')}
                    </h2>

                    <div className='grid grid-cols-2 md:grid-cols-5 justify-between w-fit mx-auto md:w-full gap-6 pt-12'>
                        <div className='team-box'>
                            <figure>
                                <img src={teamFrameImg} alt='' className='cover-img'/>
                            </figure>
                            <p className='font-semibold text-lg pt-2'>
                                Pippo baudo
                            </p>
                            <p>
                                Albero
                            </p>
                        </div>
                        <div className='team-box'>
                            <figure>
                                <img src={teamFrameImg} alt='' className='cover-img'/>
                            </figure>
                            <p className='font-semibold text-lg pt-2'>
                                Pippo baudo
                            </p>
                            <p>
                                Albero
                            </p>
                        </div>
                        <div className='team-box'>
                            <figure>
                                <img src={teamFrameImg} alt='' className='cover-img'/>
                            </figure>
                            <p className='font-semibold text-lg pt-2'>
                                Pippo baudo
                            </p>
                            <p>
                                Albero
                            </p>
                        </div>
                        <div className='team-box'>
                            <figure>
                                <img src={teamFrameImg} alt='' className='cover-img'/>
                            </figure>
                            <p className='font-semibold text-lg pt-2'>
                                Pippo baudo
                            </p>
                            <p>
                                Albero
                            </p>
                        </div>
                        <div className='team-box'>
                            <figure>
                                <img src={teamFrameImg} alt='' className='cover-img'/>
                            </figure>
                            <p className='font-semibold text-lg pt-2'>
                                Pippo baudo
                            </p>
                            <p>
                                Albero
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='py-12 relative overflow-hidden'>
                <div className='max-w-5xl mx-auto pt-12 px-6 relative text-center'>
                    <h2 className='font-semibold text-3xl sm:text-4xl'>
                        {t("OurMission")}
                    </h2>
                    <div className='pt-6 relative'>
                        <p className='max-w-2xl mx-auto'>
                            {t("OurMissionDescription")}
                        </p>
                        <ReactSVG src={missionArrow1} className='hidden lg:block absolute -left-10 top-14'/>
                        <ReactSVG src={missionArrow2} className='hidden lg:block absolute -right-24 top-14'/>
                    </div>
                    <div className='flex flex-col gap-12 max-w-2xl lg:max-w-full mx-auto mt-12'>
                        <p className='relative lg:max-w-sm lg:pl-24 lg:top-28'>
                            {t("OurMissionPar1")}
                        </p>
                        <p className='lg:max-w-sm ms-auto lg:pr-24'>
                            {t("OurMissionPar2")}
                        </p>
                    </div>
                </div>
                <div className='max-w-7xl mx-auto overflow-visible inset-x-0 h-32 relative'>
                    <img src={missionBg} alt='' className='absolute min-w-fit left-80 -bottom-72'/>
                </div>
            </section>
        </MainLayout>
    );
}

export default AboutPage;
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useGlobalContext } from '../../context/Context';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import AuthPassInput from '../../components/AuthPassInput';
import GoogleSingInButton from '../../components/GoogleSignInButton';
import closeIc from '../../assets/x-ic.svg';
import xRedIcon from "../../assets/x-circle-red.svg";

function LoginModal() {
    const { authClient, switchModal } = useGlobalContext();
    const { t } = useTranslation();
    const [authError, setAuthError] = useState();
    const [fields, setFields] = useState({});
    const [loading, setLoading] = useState(false);
    const [keepLogged, setKeepLogged] = useState(false);

    const changeHandler = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setFields(prev => ({ ...prev, [name]:value }));
    }

    const close = () => {
        switchModal();
    }

    const onLoginResult = (result) => {
        setLoading(false);

        if (!result.success) {
            setAuthError(result.message);
            return;
        }

        switchModal();
    }

    const submitHandler = (event) => {
        event.preventDefault();
        event.target.reset();
        setLoading(true);
        
        authClient.login(fields.email, fields.password, keepLogged)
            .then(onLoginResult);
    }
    
    const switchToForgotPass = () => {
        switchModal("forgotPass");
    }

    const switchToRegister = () => {
        switchModal("register");
    }

    return (
        <div className='fixed bg-black/75 inset-0 items-center z-50'>
            <div className='modal'>
                <button className='dialog-close-btn' onClick={close}>
                    <ReactSVG src={closeIc} title='Close' />
                </button>

                <h1 className='font-bold pt-6 pb-4 text-2xl text-center'>
                    {t('LoginTitle')}
                </h1>

                <form className='auth-form' onSubmit={submitHandler}>
                    <div className='auth-form-options'>
                        <GoogleSingInButton disabled={loading} onAuthResult={onLoginResult}/>
                    </div>

                    <p className='mt-6 text-gray-def'>
                        {t("EmailRegisterLabel")}
                    </p>

                    <label className='auth-form-field'>
                        <span className='auth-form-placeholder'>
                            {t('Email')}
                        </span>
                        <input
                            type='email'
                            name='email'
                            onChange={changeHandler}
                            required
                        />
                    </label>
                    <label className='auth-form-field'>
                        <span className='auth-form-placeholder'>
                            {t('Password')}
                        </span>
                        <AuthPassInput
                            name='password'
                            onChange={changeHandler}
                        />
                    </label>
                    <div className='auth-form-options'>
                        {authError && (
                            <span className='auth-form-error'>
                                <ReactSVG src={xRedIcon}/>
                                {t(authError)}
                            </span>
                        )}

                        <button type='submit' disabled={loading} className='btn btn-fucsia font-bold min-h-14 text-white rounded-xl'>
                            <span className='block text-base'>
                                {t('Login')}
                            </span>
                        </button>
                    </div>
                    <div className='auth-form-group font-medium text-sm'>
                        <label>
                            <input
                                type='checkbox'
                                name='keepLogged'
                                className='checkbox-input'
                                checked={keepLogged}
                                onChange={() => setKeepLogged(prev => !prev)}
                            />
                            <span className='cursor-pointer'>
                                {t('KeepLogged')}
                            </span>
                        </label>
                        <div className='basis-24 grow text-end'>
                            <span type='button' className='link-styled' onClick={switchToForgotPass}>
                                {t('ForgotPassword')}
                            </span>
                        </div>
                    </div>
                    <div className='text-start space-x-1 mt-6'>
                        <span>
                            {t('NotAlreadyRegistered')}
                        </span>
                        <button type='button' onClick={switchToRegister} disabled={loading} className='font-medium inline text-lightblue-def underline'>
                            {t('SignUpLink')}
                        </button>
                    </div>
                </form>
                <div className='pt-6 space-x-1 text-center text-sm'>
                    <span className='text-gray-def'>
                        {t("RegistrationEnding")}
                    </span>
                    <Link to="/terms-conditions" onClick={close} className='font-medium text-lightblue-def underline'>
                        {t("TermsConditions")}
                    </Link>
                    <span className='text-gray-def'>
                        {t("and")}
                    </span>
                    <Link to="/privacy-policy" onClick={close} className='font-medium text-lightblue-def underline'>
                        {t("PrivacyPolicy")}
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default LoginModal;
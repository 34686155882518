import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import { useGlobalContext } from "../../context/Context";
import MainLayout from "../../components/MainLayout";
import backBtnIcon from "../../assets/back-arrow-ic.svg";

function EditProfilePage() {
    const { authClient, authReady, loggedUser } = useGlobalContext();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [displayNameField, setDisplayNameField] = useState("");
    const [emailField, setEmailField] = useState("");
    const navigate = useNavigate();

    const isFormValid = () => {
        const errors = [];

        if (!displayNameField?.trim()) {
            errors.push("InvalidUsername");
        }

        if (!emailField?.trim()) {
            errors.push("InvalidEmail");
        }

        setErrors(errors);
        return errors.length === 0;
    }

    const onDisplayNameChange = (event) => {
        setDisplayNameField(event.target.value);
    }

    const onEmailChange = (event) => {
        setEmailField(event.target.value);
    }

    const submitHandler = (event) => {
        event.preventDefault();

        if (isFormValid() && !loading) {
            setLoading(true);

            authClient.updateCurrentProfile(displayNameField, emailField).then(result => {
                if (result.success) {
                    const message = result.emailUpdated ? "VerifyNewEmail" : "ProfileUpdated";

                    navigate("/account", {
                        state: { message },
                    });
                }

                if (result.error) {
                    setErrors([result.message]);
                }
                
                setLoading(false);
                console.log(result);
            });
        }
    }

    useEffect(() => {
        if (authReady) {
            if (!loggedUser) {
                navigate("/");
                return;
            }

            setDisplayNameField(loggedUser.displayName);
            setEmailField(loggedUser.email);
        }
    }, [authReady, loggedUser, navigate]);

    return (
        <MainLayout title={t("Profile")}>
            <section className='container-def px-6 py-12'>
                <div className='flex flex-col sm:flex-row sm:gap-12'>
                    <Link to="/account">
                        <ReactSVG src={backBtnIcon} title="Back"/>
                    </Link>

                    <div className="basis-1/2 flex flex-col gap-6">
                        <div className="text-center sm:text-start">
                            <h1 className="font-bold mb-6 text-2xl sm:text-4xl">
                                {t('Profile')}
                            </h1>
                        </div>

                        <form className="contact-form max-w-sm sm:max-w-full mx-auto sm:mx-0 text-sm sm:text-base" onSubmit={submitHandler}>
                            <label>
                                <span>
                                    {t('Username')}
                                </span>
                                <input type="text" name="displayName" value={displayNameField} onChange={onDisplayNameChange} required/>
                            </label>
                            <label>
                                <span>
                                    {t('EMailAddress')}
                                </span>
                                <input type="email" name="email" value={emailField} onChange={onEmailChange} required/>
                            </label>

                            {errors.length > 0 && (
                                <ul className="text-red-500">
                                    {errors.map((err, index) => (
                                        <li key={index}>{t(err)}</li>
                                    ))}
                                </ul>
                            )}

                            <div className="contact-form-options">
                                <button type="submit" className='btn btn-primary rounded-lg' disabled={loading}>
                                    <span className='block font-bold p-1'>
                                        {t('SaveProfile')}
                                    </span>
                                </button>
                                <Link to="/account">
                                    {t('Cancel')}
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </MainLayout>
    );
}

export default EditProfilePage;
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGlobalContext } from "../../context/Context";
import MainLayout from "../../components/MainLayout";

function EmailSignIn () {
    const { authClient } = useGlobalContext();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [authError, setAuthError] = useState();
    const [emailRequired, setEmailRequired] = useState(false);
    const navigate = useNavigate();

    const submitHandler = (event) => {
        const email = event.target.email?.value;
        event.preventDefault();
        localStorage.setItem("emailForSignIn", email.trim());
        setEmailRequired(false);
    }

    useEffect(() => {
        const link = window.location.href;
        const email = localStorage.getItem("emailForSignIn");

        if (!email || emailRequired) {
            setLoading(false);
            setEmailRequired(true);
            return;
        }

        setLoading(true);

        authClient.loginWithEmailLink(email, link).then(result => {
            setLoading(false);

            if (!result.isValidLink) {
                setAuthError("InvalidSignInLink");
                return;
            }

            if (!result.success) {
                setAuthError(result.message);
                console.error(result.error);
                return;
            }

            localStorage.removeItem("emailForSignIn");
            navigate("/account");
        });
    }, [authClient, emailRequired, navigate]);

    return (
        <MainLayout>
            <section className='container-def px-6 py-12 text-center'>
                <h1 className="font-bold text-2xl sm:text-4xl">
                    {t('Login')}
                </h1>

                <div className="mt-3">

                    {loading && (
                        <p>
                            {t('VerifyingEmailLink')}
                        </p>
                    )}

                    {authError && (
                        <p className="text-red-500">
                            {t(authError)}
                        </p>
                    )}

                    {emailRequired && (
                        <form className="contact-form max-w-sm mx-auto" onSubmit={submitHandler}>
                            <label>
                                <span className="mb-1">
                                    {t('SignInEmailRequired')}
                                </span>
                                <input type="email" name="email" placeholder={t('Email')} required/>
                            </label>
                            <div className="sm:ms-auto px-3 space-x-6 space-y-3 text-center">
                                <button type="submit" className='btn btn-primary rounded-lg' disabled={loading}>
                                    <span className='block font-bold m-1'>
                                        {t('Confirm')}
                                    </span>
                                </button>
                                <Link to="/">
                                    {t('Cancel')}
                                </Link>
                            </div>
                        </form>
                    )}
                </div>
            </section>
        </MainLayout>
    )
}

export default EmailSignIn;
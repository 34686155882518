import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from '../context/Context';
import MainLayout from '../components/MainLayout';
import profileBg from '../assets/profile_bg.svg';
import warnIc from '../assets/warn-msg-ic.svg';
import infoIc from '../assets/info-msg-ic.svg';
import arrowNextIc from '../assets/next-arrow-ic.svg';

function AccountPage() {
    const { authClient, authReady, loggedUser } = useGlobalContext();
    const { t } = useTranslation();
    const { state } = useLocation();
    const [showVerificationWarn, setShowVerificationWarn] = useState(false);
    const [message, setMessage] = useState(state?.message);
    const navigate = useNavigate();

    const verifyEmail = () => {
        setShowVerificationWarn(false);

        authClient.verifyEmail().then(result => {
            if (result.success) {
                setMessage("VerificationEmailSent");
            }

            if (result.error && result.message) {
                setMessage(result.message);
            }
        });
    }

    useEffect(() => {
        if (authReady) {
            if (!loggedUser) {
                navigate("/");
                return;
            }

            if (!loggedUser?.emailVerified) {
                setShowVerificationWarn(true);
            }
        }
    }, [authReady, loggedUser, navigate]);

    return (
        <MainLayout title={t("Account")}>
            <div className='container-def px-6'>
                <section className='flex flex-col sm:flex-row gap-3 justify-start items-center pt-12'>
                    <figure className='profile-picture sm:w-40'>
                        <img src={profileBg} alt='Profile' className='w-full h-full'/>
                    </figure>
                    <div className='px-6 relative text-center sm:text-start sm:bottom-6'>
                        <p className='mb-2 text-base sm:text-lg'>
                            {loggedUser?.displayName}
                        </p>
                        <p className='text-sm sm:text-base'>
                            {loggedUser?.email}
                        </p>
                    </div>
                </section>

                <section className='py-12'>
                    {showVerificationWarn && (
                        <div className='box flex flex-row gap-6 items-center mb-6 px-6 py-2'>
                            <ReactSVG src={warnIc}/>
                            <p className='flex flex-row flex-wrap gap-x-2 text-sm'>
                                <span className='inline-block'>
                                    {t('EmailNotVerified')}.
                                </span>
                                <button className='text-start text-orange-400 hover:underline' onClick={verifyEmail}>
                                    {t('VerifyEmail')}
                                </button>
                            </p>
                        </div>
                    )}

                    {message && (
                        <div className='box flex flex-row gap-6 items-center mb-6 px-6 py-2'>
                            <ReactSVG src={infoIc}/>
                            <p className='text-sm'>
                                <span className='inline-block'>
                                    {t(message)}
                                </span>
                            </p>
                        </div>
                    )}

                    <div className='flex flex-col sm:flex-row flex-wrap gap-6'>
                        <div className='sm:basis-1/3 account-links sm:order-1'>
                            <Link to="/account/edit">
                                <span className='font-medium'>
                                    {t('Profile')}
                                </span>
                                <ReactSVG src={arrowNextIc} className='text-lightblue-def'/>
                            </Link>
                            <Link to="/account/change-password">
                                <span className='font-medium'>
                                    {t('ChangePassword')}
                                </span>
                                <ReactSVG src={arrowNextIc} className='text-lightblue-def'/>
                            </Link>
                            <Link to="/account/update-card">
                                <span className='font-medium'>
                                    {t('PaymentMethod')}
                                </span>
                                <ReactSVG src={arrowNextIc} className='text-lightblue-def'/>
                            </Link>
                            <Link to="/account/order-history">
                                <span className='font-medium'>
                                    {t('OrderHistory')}
                                </span>
                                <ReactSVG src={arrowNextIc} className='text-lightblue-def'/>
                            </Link>
                            <Link to="/account/delete">
                                <span className='font-medium'>
                                    {t('DeleteAccount')}
                                </span>
                                <ReactSVG src={arrowNextIc} className='text-lightblue-def'/>
                            </Link>
                        </div>
                        <div className='account-plans sm:border-r sm:flex-1 sm:pr-6'>
                            <div className='account-box p-6'>
                                <h2 className='text-sm mb-2'>
                                    {t('YourPlan')}
                                </h2>
                                <h3 className='text-fucsia-def font-bold mb-3 text-2xl'>
                                    {t('StandardPlan')}
                                </h3>
                                <p className='font-semibold text-lightblue-def mb-3'>
                                    {t("RemainingCredits").replace("%n%", "X")}
                                </p>
                                <div className='flex flex-row flex-wrap gap-3 justify-between items-end'>
                                    <p className='text-sm text-gray-def'>
                                        {t("NextInvoice").replace("%price%", "200€").replace("%date%", "10/06/2024")}<br/>
                                        {t("CardEnding").replace("%card%", "Visa").replace("%n%", 9682)}<br/>
                                        {t("CardExpiry").replace("%date%", "02/2029")}<br/>
                                    </p>
                                    <Link to="/account/change-plan" className='font-medium text-lg rounded-lg'>
                                        {t('Show')}
                                    </Link>
                                </div>
                            </div>
                            
                            <div className='account-box p-6'>
                                <h2 className='text-sm mb-2'>
                                    {t('YourPackage')}
                                </h2>
                                <h3 className='text-fucsia-def font-bold mb-3 text-2xl'>
                                    Small
                                </h3>
                                <p className='font-semibold text-lightblue-def mb-3'>
                                    {t("RemainingCredits").replace("%n%", "X")}
                                </p>
                                <p className='text-sm text-gray-def'>
                                    {t("PkgExpiry").replace("%date%", "06/06/2025")}
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </MainLayout>
    );
};

export default AccountPage;
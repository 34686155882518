import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { useTranslation } from "react-i18next";
import MainLayout from "../../components/MainLayout";
import backBtnIcon from "../../assets/back-arrow-ic.svg";

function UpdateCardPage() {
    const { t } = useTranslation();

    return (
        <MainLayout title={t("UpdateCard")}>
            <section className='max-w-5xl mx-auto px-6 py-12'>
                <div className='flex flex-col sm:flex-row relative'>
                    <div className="basis-14">
                        <Link to="/account" className="basis-14">
                            <ReactSVG src={backBtnIcon} title="Back"/>
                        </Link>
                    </div>
                    <div className="flex flex-col max-w-sm mx-auto gap-6">
                        <h1 className="font-bold text-2xl sm:text-4xl text-center">
                            {t('UpdateCard')}
                        </h1>
                        <p className="font-light text-xs sm:text-sm">
                            {t('UpdateCardInfo')}
                        </p>

                        <form className="flex flex-col text-sm sm:text-base">
                            <div className="payment-options">
                                <label>
                                    <input type="radio" name="payment-method"/>
                                    <span>
                                        {t('CreditDebitCard')}
                                    </span>
                                </label>
                                <label>
                                    <input type="radio" name="payment-method"/>
                                    <span>
                                        {t('Paypal')}
                                    </span>
                                </label>
                            </div>
                            <div className="mx-auto mt-6 space-x-6 space-y-3">
                                <button className='btn btn-fucsia rounded-lg'>
                                    <span className='block font-bold p-1'>
                                        {t('EditPaymentInformation')}
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="basis-14"></div>
                </div>
            </section>
        </MainLayout>
    );
}

export default UpdateCardPage;
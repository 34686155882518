import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import coinsIc from "../assets/coins-ic.svg";

function PricingBox({ name, price, credits, description, bestMark = false, iconCount = 0 }) {
    const { t } = useTranslation();

    return (
        <div className='box pricing-box' data-best-mark={bestMark}>
            <h3 className="font-medium mt-3">
                {name}
            </h3>
            <p className='font-medium mt-1 text-xl lg:text-2xl'>
                {price}
            </p>
            <div className="flex flex-wrap items-center gap-2 mt-1 text-lightblue-def">
                <span className="font-semibold text-lg lg:text-xl">
                    {credits} {t("credits")}
                </span>
                <div className="inline-flex gap-1">
                    {[...Array(iconCount)].map((_, index) => (
                        <ReactSVG key={index} src={coinsIc}/>
                    ))}
                </div>
            </div>
            
            {description && (
                <p className='mt-3 text-sm'>
                    {description}
                </p>
            )}

            {bestMark && (
                <div className='b-mark font-semibold text-xs'>
                    {t('Best')}
                </div>
            )}

            <div className="font-semibold mb-2 mt-auto py-4 text-lg">
                <button className='btn btn-secondary rounded-xl w-full'>
                    {t('GetStartedBtn')}
                </button>
            </div>
        </div>
    );
};

export default PricingBox;
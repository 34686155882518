import analysisParams from "../resources/analysis-params.json";

function useAnalysisTools() {
    const getDateString = (timestamp) => {
        if (!timestamp) {
            return "InvalidDate";
        }

        const date = new Date(
            timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000
        );

        return date.toLocaleDateString();
    }

    const parseAnalysisStatus = (status) => {
        switch (status) {
            case 0:
                return "AnalysisInProgress";
                
            case 1:
                return "AnalysisCompleted";
                
            case 2:
                return "AnalysisError";

            default:
                return `Status: ${status}`;
        }
    }

    const isWordCountGood = (count) => {
        return count > analysisParams.minWorldCount;
    }

    return {
        getDateString,
        parseAnalysisStatus,
        isWordCountGood,
    };
}

export default useAnalysisTools;
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from '../assets/logo.svg';
import emIcon from '../assets/mail.svg';
import inIcon from '../assets/linkedin.svg';
import navLinks from '../resources/nav-links.json';

function Footer() {
    const { t } = useTranslation();

    return (
        <footer className='ws-footer'>
            <div className='flex flex-col sm:flex-row px-6 sm:px-12 pt-12 sm:pb-6 gap-x-6 gap-y-12 items-center'>
                <div className='flex-1'>
                    <img src={logo} alt='Origo'/>
                </div>

                <div className='flex flex-1 justify-center text-center'>
                    <ul className='flex flex-col sm:flex-row gap-6'>
                        {navLinks.map((item, index) => (
                            <li key={index}>
                                <Link to={item.path}>
                                    {t(item.label)}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className='flex flex-1 justify-end items-end gap-3'>
                    <a href='https://linkedin.com' target='_blank' rel='noreferrer'>
                        <img src={inIcon} alt='Linkedin'/>
                    </a>
                    <a href='mailto:info@origoai.cloud' rel='noreferrer'>
                        <img src={emIcon} alt='E-Mail'/>
                    </a>
                </div>
            </div>
            
            <div className='flex flex-col sm:flex-row items-center px-6 sm:px-12 pt-6 pb-8 gap-y-6 text-sm'>
                <p className='flex-1'>
                    <i className="bi bi-c-circle"></i>
                    <span className='ms-1'>Origo 2024</span>
                </p>
                <nav className='flex flex-wrap gap-3 justify-center'>
                    <Link to='/terms-conditions'>{t('TermsConditions')}</Link>
                    <Link to='/cookie-declaration'>{t('CookieDeclaration')}</Link>
                    <Link to='/privacy-policy'>{t('Privacy policy')}</Link>
                </nav>
            </div>
        </footer>
    );
};

export default Footer;
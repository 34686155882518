import { useEffect, useRef } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import { useGlobalContext } from '../context/Context';
import UserDropdown from './UserDropdown';
import LanguageDropdown from './LanguageDropdown';
import navlinks from '../resources/nav-links.json';
import logo from '../assets/logo.svg';
import menuIcon from '../assets/menu_icon.svg';
import backIcon from '../assets/back-arrow-ic-sm.svg';
import userIc from "../assets/user-dropdown.svg";

function Navigation() {
    const { loggedUser } = useGlobalContext();
    const { t, i18n } = useTranslation();
    const headerRef = useRef();
    const burgerRef = useRef();
    const location = useLocation();
    const isAccountPage = location.pathname.startsWith("/account");
    
    const toggleBurger = () => {
        const menuEl = burgerRef.current;
        
        if (menuEl) {
            const visible = !parseInt(menuEl.dataset.visible);
            menuEl.style.left = visible ? 0 : "100%";
            menuEl.dataset.visible = visible ? 1 : 0;
            document.body.classList.toggle("burger-open", visible);
        }
    }

    useEffect(() => {
        let lastScrollTop = 0;
        
        const handleScroll = () => {
            const headerEl = headerRef.current;
            const scrollTop = window.scrollY || document.documentElement.scrollTop;
            const scrollingDown = scrollTop > lastScrollTop;

            if (headerEl) {
                headerEl.style.top = scrollingDown ? `-${headerEl.offsetHeight}px` : '0';
                headerEl.classList.toggle("border-b", scrollTop > 0);
            }

            lastScrollTop = scrollTop;
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const menuEl = burgerRef.current;
        
        if (menuEl && parseInt(menuEl.dataset.visible)) {
            menuEl.style.left = "100%";
            menuEl.dataset.visible = 0;
            document.body.classList.remove("burger-open");
        }
    }, [location]);

    return (
        <header className='ws-header' ref={headerRef}>
            <nav className='container-def flex flex-row flex-wrap gap-3 justify-between items-center py-3 px-6'>
                <Link to="/">
                    <img src={logo} alt='Origo' className='w-24 sm:w-28'/>
                </Link>

                <ul className='hidden sm:flex flex-row gap-6 pb-2 relative'>
                    {navlinks.map((link, index) => (
                        <li key={index}>
                            <NavLink to={link.path} className='nav-item'>
                                {t(link.label)}
                            </NavLink>
                        </li>
                    ))}
                    <li>
                        <UserDropdown>
                            <span className='nav-item' aria-selected={isAccountPage}>
                                {loggedUser ? loggedUser.displayName : t('Account')}
                            </span>
                        </UserDropdown>
                    </li>
                    <li>
                        <LanguageDropdown>
                            <span className='nav-item w-6'>{i18n.language?.toUpperCase()}</span>
                        </LanguageDropdown>
                    </li>
                </ul>

                <ul className='burger-menu sm:hidden gap-6' ref={burgerRef}>
                    {navlinks.map((link, index) => (
                        <li key={index}>
                            <NavLink to={link.path} className='nav-item'>
                                {t(link.label)}
                            </NavLink>
                        </li>
                    ))}
                    <li className='burger-menu-options'>
                        <div className='flex-1'>
                            <button onClick={toggleBurger}>
                                <ReactSVG src={backIcon} title='Back'/>
                            </button>
                        </div>

                        <div className='flex flex-row gap-6 ms-auto'>
                            <UserDropdown>
                                <ReactSVG src={userIc} title='User'/>
                            </UserDropdown>
                            <LanguageDropdown>
                                <span>{i18n.language?.toUpperCase()}</span>
                            </LanguageDropdown>
                        </div>
                    </li>
                </ul>

                <div className='flex flex-row gap-3 ms-auto sm:hidden text-xl'>
                    <button onClick={toggleBurger}>
                        <ReactSVG src={menuIcon} title='Menu'/>
                    </button>
                </div>
            </nav>
        </header>
    );
}

export default Navigation;
import { useEffect } from "react";
import { useGlobalContext } from "../context/Context";

function MainLayout({ title, children }) {
    const { apiClient } = useGlobalContext();

    useEffect(() => {
        const appName = apiClient.appName;
        document.title = title ? `${appName} | ${title}` : appName;
        window.scrollTo(0, 0);
    });

    return (
        <main className='main-layout'>
            {children}
        </main>
    );
};

export default MainLayout;
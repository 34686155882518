import { useGlobalContext } from '../../context/Context';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import closeIc from '../../assets/x-ic.svg';
import emailIcon from '../../assets/ic_outline-email.svg';

function VerificationSentModal() {
    const { switchModal } = useGlobalContext();
    const { t } = useTranslation();

    const submitHandler = (event) => {
        event.preventDefault();
        switchModal();
    }

    return (
        <div className='fixed bg-black/75 inset-0 items-center z-50'>
            <div className='modal'>
                <button className='dialog-close-btn' onClick={() => switchModal()}>
                    <ReactSVG src={closeIc} title='Close' />
                </button>

                <h1 className='font-bold pt-6 pb-4 text-2xl text-center'>
                    {t('VerifyEmail')}
                </h1>

                <form className='auth-form' onSubmit={submitHandler}>
                    <p className='mt-6 pb-3 text-center text-sm'>
                        <ReactSVG src={emailIcon} className='inline-block'/>
                        <span className='block mt-1'>
                            {t('VerifyEmailCaption')}
                        </span>
                    </p>

                    <div className='auth-form-options'>
                        <button type='submit' className='btn btn-fucsia font-bold min-h-14 text-white rounded-xl'>
                            <span className='block text-base'>
                                {t('Confirm')}
                            </span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default VerificationSentModal;
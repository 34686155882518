import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import useAnalysisTools from "../../hooks/useAnalysisTools";
import Popover from "../../components/Popover";
import GaugeChart from "../../components/GaugeChart";

function AnalysisContent({ name, wordCount, textAnalysis, keywords, rating, url, showRename }) {
    const { t } = useTranslation();
    const { isWordCountGood } = useAnalysisTools();
    const parsedRating = parseInt(rating) || 0;
    const parsedWordCount = parseInt(wordCount) || 0;

    const keywordsFound = useMemo(() => {
        const kf = keywords?.keywordAnalysis;
        const array = [];

        if (kf) {
            kf.split("\n").forEach(string => {
                const newString = string.trim().replace("- ", "");
                array.push(newString);
            });
        }

        return array.join(", ");
    }, [keywords]);
    
    const keywordsSuggested = useMemo(() => {
        const kf = keywords?.keywordResearch;
        const array = [];

        if (kf) {
            kf.split("\n").forEach(string => {
                const newString = string.trim().replace("- ", "");
                array.push(newString);
            });
        }

        return array.join(", ");
    }, [keywords]);

    const editBtnClickHandler = () => {
        if (typeof showRename === "function") {
            showRename();
        }
    }

    return (
        <section className="flex flex-col sm:flex-row gap-6 items-start">
            <div className="analysis-box flex flex-col sm:w-1/3 py-4 px-6">
                <div className="flex flex-wrap gap-2">
                    <h2 className="text-xl font-bold mb-1">
                        {name}
                    </h2>
                    <button onClick={editBtnClickHandler} className="text-sm">
                        {t("Edit")}
                    </button>
                </div>
                <div className="max-w-56 mx-auto w-full mt-4">
                    <GaugeChart rating={parsedRating}>
                        <p className="absolute bottom-0 font-bold inset-x-0 mb-1 text-3xl text-fucsia-def">
                            {parsedRating}
                        </p>
                    </GaugeChart>
                </div>
                <div className="mt-4">
                    <p className="font-semibold">
                        {t("AnalyzedPage")}:
                    </p>
                    <p className="break-words text-sm">
                        {url}
                    </p>
                </div>
            </div>
            <div className="analysis-box flex-1 py-4 px-6 space-y-4">
                <div>
                    <h2 className="mb-1 text-xl font-bold">
                        {t("Advices")}
                    </h2>
                    <p>
                        {textAnalysis}
                    </p>
                </div>
                <div>
                    <div className="analysis-result-title">
                        <h2 className="mb-1 text-xl font-bold">
                            {t("WordCountTitle")}
                        </h2>
                        <Popover>
                            {t("WordCountDescription")}
                        </Popover>
                    </div>
                    <p>
                        {(isWordCountGood(parsedWordCount) ? (t("WordCountGood")) : t("WordCountInsuff")).replace("%count%", wordCount)}
                    </p>
                </div>
                <div>
                    <div className="analysis-result-title">
                        <h2 className="mb-1 text-xl font-bold">
                            {t("Keywords")}
                        </h2>
                        <Popover>
                            {t("KeywordsDescription")}
                        </Popover>
                    </div>
                    <p>
                        {t("KeywordsText").replace("%kf%", keywordsFound).replace("%ks%", keywordsSuggested)}
                    </p>
                </div>
            </div>
        </section>
    );
}

export default AnalysisContent;
import appSettings from '../resources/app-settings.json';

class ApiClient {
    #appName;
    #apiUrl;
    #currentUser;
    #debugEnabled;
    #webSockerUrl;

    get appName () {
        return this.#appName;
    }

    get webSocketUrl () {
        return this.#webSockerUrl;
    }

    constructor () {
        this.#appName = appSettings.appName;
        this.#apiUrl = appSettings.apiURL;
        this.#debugEnabled = appSettings.debug;
        this.#webSockerUrl = appSettings.webSocketURL;
    }

    async #getResultFromResponse (response) {
        const success = [200, 201].includes(response.status);
        const resJson = await response.json();

        const result = {
            response: resJson,
            statusCode: response.status,
            success,
        };

        if (!success) {
            result.message = resJson.message;
        }

        return result;
    }

    #getResultFromError (error) {
        const result = {
            success: false,
            statusCode: -1,
            message: "FetchFailed",
            error,
        }

        if (this.#debugEnabled) {
            console.error("Fetch failed", error);
        }

        return result;
    }

    openWebSocket () {
        const ws = new WebSocket(this.#webSockerUrl);
        const uid = this.#currentUser?.uid;

        if (uid) {
            ws.addEventListener("open", () => {
                const message = JSON.stringify({ uid });
                ws.send(message);
            });
        }

        return ws;
    }

    async indexAnalysis () {
        const route = `${this.#apiUrl}/analysis`;

        try {
            const idtoken = await this.#currentUser?.getIdToken();

            const response = await fetch(route, {
                method: "GET",
                headers: { idtoken },
            });

            return await this.#getResultFromResponse(response);
        }
        catch (error) {
            return this.#getResultFromError(error);
        }
    }

    async showAnalysis (id) {
        const route = `${this.#apiUrl}/analysis/${id}`;

        try {
            const idtoken = await this.#currentUser?.getIdToken();

            const response = await fetch(route, {
                method: "GET",
                headers: { idtoken },
            });

            return await this.#getResultFromResponse(response);
        }
        catch (error) {
            return this.#getResultFromError(error);
        }
    }

    async repeatAnalysis (id) {
        const route = `${this.#apiUrl}/analysis/${id}/repeat`;

        try {
            const idtoken = await this.#currentUser?.getIdToken();

            const response = await fetch(route, {
                method: "POST",
                headers: { idtoken },
            });

            return await this.#getResultFromResponse(response);
        }
        catch (error) {
            return this.#getResultFromError(error);
        }
    }
    
    async renameAnalysis (id, name) {
        const route = `${this.#apiUrl}/analysis/${id}`;

        try {
            const idtoken = await this.#currentUser?.getIdToken();

            const response = await fetch(route, {
                body: JSON.stringify({ name }),
                method: "PATCH",
                headers: {
                    idtoken,
                    "Content-Type": "application/json",
                },
            });

            return await this.#getResultFromResponse(response);
        }
        catch (error) {
            return this.#getResultFromError(error);
        }
    }

    async storeAnalysis (body) {
        const route = `${this.#apiUrl}/analysis`;

        try {
            const idtoken = await this.#currentUser?.getIdToken();

            const response = await fetch(route, {
                body,
                method: "POST",
                headers: { idtoken },
            });

            return await this.#getResultFromResponse(response);
        }
        catch (error) {
            return this.#getResultFromError(error);
        }
    }

    updateUser (user) {
        this.#currentUser = user;

        if (this.#debugEnabled) {
            console.log("Updated user:", user?.uid);
        }
    }
}

export default ApiClient;
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import MainLayout from "../../components/MainLayout";
import backBtnIcon from "../../assets/back-arrow-ic.svg";

function ChangePlanPage() {
    const { t } = useTranslation();

    return (
        <MainLayout title={t("ChangePlan")}>
            <section className='container-def px-6 py-12'>
                <div className='flex flex-col sm:flex-row gap-6 sm:gap-12 relative'>
                    <div className="basis-14">
                        <Link to="/account" className="basis-14">
                            <ReactSVG src={backBtnIcon} title="Back"/>
                        </Link>
                    </div>

                    <div className="flex-1">
                        <div className='account-box p-6 max-w-2xl'>
                            <h2 className='text-sm mb-2'>
                                {t('YourPlan')}
                            </h2>
                            <h3 className='text-fucsia-def font-bold mb-3 text-2xl'>
                                {t('StandardPlan')}
                            </h3>
                            <p className='font-semibold text-lightblue-def mb-3'>
                                {t("RemainingCredits").replace("%n%", "X")}
                            </p>
                            <div className='flex flex-row flex-wrap gap-3 justify-between items-end'>
                                <p className='text-sm text-gray-def'>
                                    {t("NextInvoice").replace("%price%", "200€").replace("%date%", "10/06/2024")}<br/>
                                    {t("CardEnding").replace("%card%", "Visa").replace("%n%", 9682)}<br/>
                                    {t("CardExpiry").replace("%date%", "02/2029")}<br/>
                                </p>
                            </div>
                        </div>
                        <div className="py-6 flex flex-wrap gap-6">
                            <Link to="/pricing" className='btn btn-primary rounded-lg'>
                                <span className="block font-bold p-1">
                                    {t('ChangePlan')}
                                </span>
                            </Link>
                            <button>
                                {t('CancelPlan')}
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </MainLayout>
    );
}

export default ChangePlanPage;
import { useEffect, useRef, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { usePopper } from 'react-popper';
import infoIc from "../assets/info-ic.svg";

const Popover = ({ children }) => {
    const buttonRef = useRef();
    const popoverRef = useRef(null);
    const [arrowElement, setArrowElement] = useState(null);
    const [show, setShow] = useState(false);

    const { styles, attributes, update } = usePopper(buttonRef.current, popoverRef.current, {
        placement: 'right',
        modifiers: [
            {
                name: 'arrow',
                options: { element: arrowElement },
            },
            {
                name: 'preventOverflow',
                enabled: true,
            },
            {
                name: 'flip',
                options: {
                    fallbackPlacements: ['bottom', 'top'],
                },
            },
        ],
    });

    useEffect(() => {
        if (show && update) {
            update();
        }
    }, [show, update]);

  return (
    <div>
        {show && (
            <div ref={popoverRef} style={styles.popper} {...attributes.popper}>
                <div className="popover-content popover-theme-border">
                    <p>{children}</p>
                </div>
                <div ref={setArrowElement} className="popover-arrow" style={styles.arrow} />
            </div>
        )}
        <button ref={buttonRef} onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
            <ReactSVG src={infoIc} title='Info'/>
        </button>
    </div>
  );
};

export default Popover;
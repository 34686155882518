import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import analysisFields from "../../resources/analysis-fields.json";
import asteriskIcon from "../../assets/tabler_asterisk.svg";
import backBtnIcon from "../../assets/back-arrow-ic-sm.svg";
import { useEffect } from "react";

function ConfigureStep({ data, setData }) {
    const { t } = useTranslation();

    const saveUserFields = () => {
        const userFields = {};

        analysisFields.forEach(field => {
            userFields[field.name] = data[field.name];
        });

        sessionStorage.setItem("lastAnalysisFields", JSON.stringify(userFields));
    }

    const isStepCompleted = () => {
        return analysisFields.every(field => {
            const userInput = data[field.name];
            return !field.required || (userInput && userInput.trim() !== "");
        });
    }

    const fieldChangeHandler = (event) => {
        const { name, value } = event.target;
        setData(prev => ({ ...prev, [name]:value }));
    }

    useEffect(() => {
        const lastUserFields = JSON.parse(sessionStorage.getItem("lastAnalysisFields"));
        setData(prev => ({ ...prev, ...lastUserFields }));
    }, [setData]);

    return (
        <div className="pt-3">
            <div className="px-6">
                <h2 className="font-semibold py-6 text-center text-2xl">
                    {t("ConfigureStepTitle")}
                </h2>
                <div className="max-w-lg mx-auto space-y-4">
                    {analysisFields.map((field, index) => (
                        <label key={index} className="analyze-configuration-label">
                            <p className="font-medium">
                                {t(field.label)}
                            </p>

                            <div className="relative">
                                {field.required && (
                                    <ReactSVG src={asteriskIcon} className="absolute right-2 top-2"/>
                                )}
                                <input
                                    type="text"
                                    name={field.name}
                                    value={data[field.name] ?? ""}
                                    placeholder={t(field.placeholder)}
                                    onChange={fieldChangeHandler}
                                    required={field.required}
                                />
                            </div>
                        </label>
                    ))}
                </div>
            </div>
            <div className="border-t flex flex-wrap gap-3 mt-6 p-6">
                <button type="reset" className="btn">
                    <ReactSVG src={backBtnIcon} title="Back"/>
                </button>
                <button type="submit" disabled={!isStepCompleted()} onClick={saveUserFields} className="btn btn-primary ms-auto rounded-lg">
                    <span className="inline-block p-1 font-semibold">
                        {t("Analyze")}
                    </span>
                </button>
            </div>
        </div>
    );
}

export default ConfigureStep;